@import url("https://statics.goorm.io/fonts/GoormSans/v1.0.0/GoormSans.min.css");

@font-face {
  font-family: "Freesentation-2ExtraLight";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-2ExtraLight.woff2")
    format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Freesentation-3Light";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-3Light.woff2")
    format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Freesentation-4Regular";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-4Regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Freesentation-5Medium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-5Medium.woff2")
    format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Freesentation-7Bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-7Bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TTHakgyoansimTtwimteulR";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2402_keris@1.0/TTHakgyoansimTtwimteulR.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
